<template>
  <div class="w1092">
    <div class="pagenamew">
      <span class="couponn"><span>{{userData.memNick}}</span>{{$t('front.point.userPointInfo')}}</span>
    </div>

    <ul class="pointlist">
      <li class="on">
        <em>{{$t('front.point.leftPoint')}}</em>
        <p><span>{{thousand(sum ? sum.pointAmt : 0)}}</span>P</p>
      </li>
      <li>
        <em>{{$t('front.point.monthPoint')}}</em>
        <p><span>{{thousand(sum ? sum.thisMonthPoint : 0)}}</span>P</p>
      </li>
      <li>
        <em>{{$t('front.point.prevMonthPoint')}}</em>
        <p><span>{{thousand(sum ? sum.preMonthPoint : 0)}}</span>P</p>
      </li>
      <li>
        <em>{{$t('front.point.allPoint')}}</em>
        <p><span>{{thousand(sum ? sum.totalInPoint : 0)}}</span>P</p>
      </li>
      <li>
        <em>{{$t('front.point.allUsePoint')}}</em>
        <p><span>{{thousand(sum ? sum.totalOutPoint : 0)}}</span>P</p>
      </li>
    </ul>

    <date-filter @search="loadList(1)"
                 @update="onChangeDate"
                 :startDate="searchDate.startDate"
                 :endDate="searchDate.endDate"/>

    <div class="board mb20">
        <ul class="boardw first">
          <li class="">{{$t('front.board.applyDay')}}</li>
          <li class="">{{$t('front.board.divUse')}}</li>
          <li class="">{{$t('front.board.applyPoint')}}</li>
          <li class="">{{$t('front.board.processDay')}}</li>
          <li class="">{{$t('front.board.processState')}}</li>
        </ul>
        <template v-if="list.length > 0">
          <ul class="boardw" v-for="item in list" v-bind:key="item.updDt">
            <li class="">{{dateFormatAll(item.regDt)}}</li>
            <li class="">{{$t('front.board.moneychange')}}</li>
            <li class="">{{thousand(item.point_inAmt.replace('-', ''))}}</li>
            <li class="">{{dateFormatAll(item.updDt)}}</li>
            <li class="blc">{{$t('front.board.complet')}}</li>
          </ul>
        </template>
        <template v-else>
          <ul class="boardw">
            {{$t('front.common.notFoundList')}}
          </ul>
        </template>
      </div>

    <pagination
        :pageNum="pageInfo.page"
        :pageSize="pageInfo.count_per_list"
        :totalCount="pageInfo.tatal_list_count"
        @goToPage="loadList" />

  </div>
</template>

<script>
import DateFilter from '@/components/ui/DateFilter'
import Pagination from '@/components/ui/Pagination'
import { getPointList } from '@/api/point'

export default {
  name: 'pointUselist',
  components: { DateFilter, Pagination },
  data () {
    return {
      list: [],
      sum: {},
      pageInfo: {
        page: 1,
        count_per_list: 10,
        tatal_list_count: 0
      }
    }
  },
  created () {
    this.loadList()
  },
  methods: {
    loadList (page) {
      const params = {
        pointType: 'out',
        page: page || this.pageInfo.page,
        startDate: this.searchDate.startDate,
        endDate: this.searchDate.endDate
      }

      console.log(params)

      getPointList(params).then(response => {
        const result = response.data
        if (result.resultCode === '0') {
          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }
          const data = result.data
          this.list = data.list
          this.sum = data.sum
        }
      })
    }
  }
}
</script>
<style scoped>
.pointlist {display: flex;gap: 18px;margin-bottom: 20px;}
.pointlist li {width: 100%;border-radius: 10px;border: 2px solid #333;background: #000;color: #eee;text-align: center;padding: 17px 0;}
.pointlist li em {font-size: 14px;margin-bottom: 10px;display: block;}
.pointlist li p {font-size: 30px;}
.pointlist li span {font-weight: bold;color: #fff;}
.boardw li:nth-child(2){word-break: break-all;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
